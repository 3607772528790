/* Loading Bar  css */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 57.2px;
    /* Adjust top offset */
    left: 250px;
    /* Adjust based on the sidebar width */
    width: calc(100% - 250px);
    /* Adjust based on the sidebar width */
    height: calc(100% - 57.2px);
    /* Adjust height to exclude the top offset */
    background-color: rgba(255, 255, 255, 0.8);
    /* Optional: background overlay */
    z-index: 9999;
    /* Make sure it's above other elements */
}

.loading-container-in-trip {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 57.2px;
    /* Adjust top offset */
    left: 0;
    /* Adjust based on the sidebar width */
    width: 100%;
    /* Adjust based on the sidebar width */
    height: calc(100% - 57.2px);
    /* Adjust height to exclude the top offset */
    background-color: rgba(255, 255, 255, 0.8);
    /* Optional: background overlay */
    z-index: 9999;
    /* Make sure it's above other elements */
}

/* 기본 달력 스타일 */
.rbc-calendar {
    width: 100%;
    height: 100%;
}

/* 모바일 뷰 스타일 조정 */
@media (max-width: 768px) {
    .rbc-calendar {
        font-size: 12px;
        /* 모바일에서 작은 폰트 크기 */
        width: 90%;
        /* 모바일에서 너비 조정 */
        height: 400px;
        /* 모바일에서 높이 조정 */
        margin: auto;
        /* 중앙 정렬 */
    }

    .rbc-header,
    .rbc-month-header,
    .rbc-day-header {
        font-size: 10px;
        /* 더 작은 폰트 크기 */
    }

    .rbc-event {
        font-size: 8px;
        /* 이벤트 폰트 크기 조정 */
    }

    body {
        width: 60vh;
        min-width: 60vh !important;
    }
}